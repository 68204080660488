
/* eslint-disable @typescript-eslint/no-explicit-any */
import WaiverForm from '@/components/Waiver.vue';
import VueSignature from '@/components/npm/shaynewang/vue-signature.vue';
// import PDFDoc from 'pdfkit';
// import { toPdf } from '@/store/data/Waivers';
import { Component, Vue } from 'vue-property-decorator';
import { Base64Encode } from 'base64-stream';

@Component({
  components: { WaiverForm, VueSignature }
})
export default class Waiver extends Vue {
  private data = {
    dancer: '',
    guardian: '',
    email: ''
  }

  get signature (): any {
    return this.$refs.signature;
  }

  get waivers () {
    return this.$store.state.waivers;
  }

  // http://127.0.0.1:3000/student/waiver/submission

  handleClear () {
    this.signature.clear();
  }

  onSubmit () {
    const doc: any = {};
    const stream = doc.pipe(new Base64Encode());
    const { width, margins: { left, right } } = doc.page;
    const opts = { width: width - left - right, align: 'center' };

    doc.fontSize(24);
    doc.text('Catch The Beat Dance Academy', opts);

    doc.fontSize(20);
    doc.text('Waiver & Release', opts)
      .moveDown(1);

    this.waivers.forEach((waiver: any) => {
      doc.fontSize(18);
      doc.text(waiver.type);
      doc.fontSize(12);
      // toPdf(waiver.content, doc);
      doc.text().moveDown(0.75);
    });

    doc.image(
      Buffer.from(this.signature.save().replace('data:image/png;base64,', ''), 'base64'),
      { width: (width - left - right) / 2 }
    );
    doc.end();

    let content = '';
    stream.on('data', (chunk: string) => { content += chunk; });
    stream.on('end', async () => {
      await fetch(
        'http://127.0.0.1:3000/student/waiver/submission', {
          method: 'POST',
          body: JSON.stringify({
            pdf: content,
            ...this.data
          })
        });
    });
  }
}
