
import Vue from 'vue'

export default Vue.extend({
  name: 'WaiverForm',

  data: () => ({
    dancerName: '',
    guardianName: '',
    legalDancer: false,
    guardianEmail: ''
  }),

  computed: {
    waivers () {
      return this.$store.state.waivers;
    }
  }
})
